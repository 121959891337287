import ContentBlock from "../../components/UI/ContentBlock"
import ContentCard from "../../components/UI/ContentCard"
import {Button} from "antd"
import styled from "styled-components"
import ContentHeader from "../../components/UI/ContentHeader"
import {useEffect} from "react"
import CustomerReview from "../../components/CustomerReview"

const RoutingScreen = () => {

	useEffect(() => {
		document.title = 'АТС24 — Маршрутизация, планирование маршрутов, автоматическая маршрутизация'
	}, [])

	return (
		<>
			<ContentBlock top={60} bottom={0}>
				<ContentCard title="Маршрутизация" large>
					<p>
						АТС24 — простой и мощный инструмент для планирования маршрутов. Возможность как ручного, так и
						автоматического планирования с возможностью ручной корректировки.
					</p>
					<p>
						<Button color="default" variant="solid" size="large">
							Попробовать бесплатно
						</Button>
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={40}>
				<Section>
					<img width="100%" src="./assets/img/png/home/main.png"/>
				</Section>
			</ContentBlock>

			<ContentBlock>
				<ContentCard
					title="Всей командой"
					media={{
						url: "/assets/img/jpg/routing/group.jpg",
						width: 780
					}}
				>
					<p>
						Разделяйте точки по ответственности и маршрутизируйте их всей командой одновременно. Вы всегда
						будете видеть, что делают ваши коллеги.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock background="#E9FAFF">
				<ContentCard title="Автоматически или вручную" small>
					<p>
						Любая автоматизация — это прекрасно. Но мы считаем, что ручной режим не менее важен, поэтому у
						нас есть оба варианта, включая возможность удобно корректировать маршрут вручную.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={0} background="#E9FAFF">
				<ContentCard radius={10}
							 reverse title="Автоматически" mini
							 media={{
								 url: "/assets/img/jpg/routing/auto.jpg",
								 width: 780
							 }}
				>
					<p>
						Мы используем только необходимые и понятные настройки автоматического планирования, а более
						точные подстройки алгоритм выполнит самостоятельно.
					</p>
					<p>
						Вам нужно лишь выбрать склад, указать приоритет и ограничения на количество адресов или время
						работы — дальше автомат сделает всё за вас.
					</p>
				</ContentCard>

				<ContentCard
					title="Ручной режим" mini
					media={{
						url: "/assets/img/jpg/routing/manual.jpg",
						width: 780
					}}
				>
					<p>
						Более простого, удобного и информативного конструктора маршрутов вы ещё не встречали.
					</p>
					<p>
						Всё, как в операционных системах: выделяйте, копируйте, вырезайте и вставляйте точки прямо на
						карте.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock>
				<CustomerReview
					autor="Роман Козлов, руководитель логистики"
					description="Потрясающий инструмент. Время планирования сократили с двух часов до 15 минут"
					media={{
						url: "/assets/img/png/routing/logo-kover-rent.png",
						width: 200
					}}
				/>
			</ContentBlock>
			<ContentBlock background="#f5f5f5" bottom={60}>
				<ContentCard title="Наши преимущества">
					<p>
						Ключевые преимущества нашего инструмента планирования.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock background="#f5f5f5" top={0}>
				<TilesWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/routing/browser.jpg"/>
						<ContentHeader mini>Работа в браузере</ContentHeader>
						<p>
							Никаких установок, серверов, выездов специалистов и других трудностей, характерных для
							классического ПО.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/routing/fast.jpg"/>
						<ContentHeader mini>Очень быстрый</ContentHeader>
						<p>
							Вы когда-нибудь работали с 10 000 точками на карте? У нас вы сможете управлять ими без
							задержек.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/routing/on-roads.jpg"/>
						<ContentHeader mini>Маршруты по дорогам</ContentHeader>
						<p>
							Мы рассчитываем маршруты строго по дорогам и предоставляем точные данные о расстояниях,
							времени и схемах движения.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/routing/map-point-info.jpg"/>
						<ContentHeader mini>Информация на карте</ContentHeader>
						<p>
							Включите отображение времени приёмки и названия клиента прямо на карте, чтобы с первого
							взгляда понимать, что это за точка.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/routing/mobile-app-optimization.jpg"/>
						<ContentHeader mini>Оптимизация в приложении</ContentHeader>
						<p>
							Водитель может в любой момент оптимизировать маршрут, если он отклонился от плана.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/routing/geocoder.jpg"/>
						<ContentHeader mini>Геокодирование</ContentHeader>
						<p>
							Нет координат клиентов? Не проблема — мы геокодируем адреса и даём возможность указать
							точное место разгрузки.
						</p>
					</TileWrapper>
				</TilesWrapper>
			</ContentBlock>

			<ContentBlock>
				<ContentCard title="Перенос точек" small
							 media={{
								 url: "/assets/img/jpg/routing/move-points.jpg",
								 width: 780
							 }}
				>
					<p>
						Транспорт сломался или водитель не вышел на смену? Вы можете быстро заменить транспортное
						средство или водителя.
					</p>
					<p>
						Если нужно перенести точки из одного маршрута в другой — просто вырежьте их и вставьте в нужный
						маршрут.
					</p>
				</ContentCard>
				<ContentCard
					reverse title="Печать документов" small
					media={{
						url: "/assets/img/jpg/routing/print.jpg",
						width: 780
					}}
				>
					<p>
						Нажмите правой кнопкой мыши на маршрут, выберите нужный документ (путевой лист или задание) и
						отправьте его на печать. Все данные о водителе, ТС и адресах будут автоматически заполнены.
					</p>
				</ContentCard>
			</ContentBlock>

			{/*<ContentBlock background="#F5F5F5">
				<FAQ questions={[
					{
						showArrow: false,
						label: 'Какие бывают категории?',
						children: 'Список категорий, их названия и возможные коэффициенты вы назначаете сами.'
					},
					{
						showArrow: false,
						label: 'Можно ли исполь?',
						children: 'Список категорий, их названия и возможные коэффициенты вы назначаете сами.'
					},
					{
						showArrow: false,
						label: 'Правда, что события могут быть абсолютно любыми?',
						children: 'Да, события и условия их возникновения вы можете придумывать сами. Мы лишь ограничиваемся набором данных, которые у нас есть. Также существует возможность обращаться к любым внешним ресурсам для более тонкой настройки.'
					},
					{
						showArrow: false,
						label: 'Могут ли баллы сниматься временно?',
						children: 'Да, баллы могут сниматься на время. К примеру: водитель опоздал на погрузку и мы снего сняли 20 баллов на одну неделю, в течении которой он получает по пониженному коэффициенту. Через неделю баллы восстанавливаются и он возвращается на привычную категорию автоматически.'
					},
					{
						showArrow: false,
						label: 'Можно ли считать категории в конце месяца?',
						children: 'Да, мы можем в течении месяца начислять водителю по базовому тарифу, а в конце месяца умножить все начисления на коэффициент итоговой категории.'
					},
					{
						showArrow: false,
						label: 'Разные маршруты — разные тарифы?',
						children: 'Да, мы можем с вами определить разные категории маршрутов или ТС, и считать их по отдельным формулам.'
					},
					{
						showArrow: false,
						label: 'Сколько стоит эта система?',
						children: 'В среднем 300-600 рублей в месяц за водителя. Финальную стоимость настройки и абонентской платы мы сможем определить после детального обсуждения именно вашей системы мотивации.'
					}
				]}/>
			</ContentBlock>*/}
		</>
	)
}

export default RoutingScreen

const Section = styled.div`
    -webkit-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 10px;
    overflow: hidden;
`
const TilesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    width: 1160px;
`
const TileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 540px;

    img {
        margin-bottom: 32px;
    }
`
